.resources-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .welcome, .test-tips {
    max-width: 900px; /* Adjust this value as needed */
    margin: 20px;
  }

.text{
    font-size: 1rem;
}
  
  /* Add media query for mobile devices */
  @media (max-width: 768px) {
    .welcome, .test-tips {
      margin: 10px;
      font-size: 14px;
    }
  }