.privacyPolicyContainer {
    margin-left: 2rem;
    margin-right: 2rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  
  h1 {
    margin-bottom: 1rem;
  }
  
  h2 {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
  
  p {
    margin-bottom: 1rem;
  }
  
  ul {
    margin-left: 1.5rem;
    margin-bottom: 1rem;
  }