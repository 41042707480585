.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
}



.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex-grow: 1;
}


